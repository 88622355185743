import React, { Component } from 'react'
// import { Section, Title, SectionButton } from '../../utils'
import styled from 'styled-components'
import { styles } from '../../utils'
// import { Link } from 'gatsby'
import img from '../../images/services.jpg'
import delivery from '../../images/delivery.jpg'
import survey from '../../images/haulout.jpg'
import corrosion from '../../images/corrosion.jpg'

export default class ServicesList extends Component {
  render() {
    return (
      <ServicesWrapper>
        <div className='container'>
          <center>
            <img src={img} alt='Walley Marine Services Provided' />
            <p>
              <i>
                Discounts are available to all <strong>FCYC</strong> (Florida
                Council of Yacht Club) and <strong>GYA</strong> (Gulf Yachting
                Assc.) members.
              </i>
            </p>

            <br />
            <div className='service-area'>
              <h1>Service Area</h1>
              <ul>
                <li>Northwest Florida</li>
                <li>Alabama</li>
                <li>Mississippi</li>
                <li>Louisiana</li>
                <li>Tennessee</li>
              </ul>
            </div>
            <hr />
            <br />
            <h1>Services Provided</h1>
          </center>
          <br />
          <div className='group'>
            <center>
              <h2>Marine Surveying</h2>

              <img src={survey} alt='marine surveying' className='survey' />
            </center>
            <ul>
              <li>
                <strong>Pre-Purchase:</strong> So that my client will have the
                comfort that is needed in making their decision on purchasing a
                vessel.
              </li>
              <li>
                <strong>Insurance Renewals:</strong> Sometimes required for your
                insurance company to continue coverage for your vessel.
              </li>
              <li>
                <strong>Condition and Valuation:</strong> For my client to know
                of any hidden secrets of a vessel and what the fair market value
                should be.
              </li>
              <li>
                <strong>Damage Claims:</strong> To assist my client in receiving
                proper compensation from an insurance company.
              </li>
              <li>
                <strong>Corrosion Investigation and Testing:</strong> Corrosion
                is the major enemy on our vessels. There are many types and
                causes. Proper testing will determine if your boat is at risk.
              </li>
            </ul>
            <div className='corrosion'>
              <center>
                <img src={corrosion} alt='typical corrosion problems' />
              </center>
            </div>
          </div>

          <hr />
          <div className='group'>
            <center>
              <h2>Pre-Purchase Consulting</h2>
            </center>
            <p>
              This can save a lot of time and expense for a client during the
              venture of finding their new vessel. I will go perform a quick
              view of the vessel for you and perform some evaluations other
              vessels of the same make that have sold or listed to be sold. It
              is very disappointing for a buyer to spend the time and expense of
              traveling to view a boat only to find out that it is not what it
              appeared to be in all of the photos.
            </p>
          </div>
          <hr />
          <div className='group'>
            <center>
              <h2>Vessel Delivery</h2>

              <img src={delivery} alt='Vessel Delivery' />
              <p>
                Delivery of vessels both Power and Sail. Let me assist you in
                getting your boat to the new location. My goal is always to get
                it to you in not only in good condition, but as efficient as
                possible. I have very experienced crew. We also always enjoy the
                owner to come along and lets learn all about this new boat.
              </p>
            </center>
          </div>
        </div>
      </ServicesWrapper>
    )
  }
}

const ServicesWrapper = styled.div`
  width: 100%;
  margin: 2rem auto;

  .service-area {
    li {
      list-style: none;
      font-size: 18px;
      color: ${styles.colors.Gray};
    }
  }

  img {
    width: 60%;
    margin-bottom: 2rem;
  }

  h1 {
    color: ${styles.colors.Gray};
    font-size: 28px;
    text-transform: uppercase;
  }

  h2 {
    color: ${styles.colors.Navy};
  }

  .group {
    margin-bottom: 3rem;
    hr {
      max-width: 70%;
    }
    h2 {
      color: ${styles.colors.Navy};
      margin-bottom: 0.8rem;
      margin-top: 2rem;
      color: ${styles.colors.Navy};
    }
  }

  .corrosion img {
    margin-top: 1rem;
  }

  .text {
    line-height: 2rem;
    word-spacing: 0.2rem;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 26px;
      padding: 0.5rem 1rem 0 1rem;
    }
    p {
      padding: 0 0.7rem;
    }
    img {
      width: 100%;
    }
  }
`
